import { Select } from 'antd';
import useSWR from 'swr';
import { useRecoilValue } from 'recoil';
import currentSite from '@recoil/current-site';
import { SelectProps } from 'antd/lib/select';

export interface FriendSelectProps extends SelectProps<string> {
  documentIdAsValue?: boolean;
  documentNameAsValue?: boolean;
  showEmpty?: boolean;
  emptyText?: string;
  defaultTop?: boolean;
  includeOwner?: boolean;
  includeArcade?: boolean;
  width?: number | string;
}

const FriendSelect = ({
  documentIdAsValue = false,
  documentNameAsValue = false,
  showEmpty = true,
  emptyText = '선택안함',
  defaultTop = false,
  includeOwner = false,
  includeArcade = false,
  width = 170,
  ...props
}: FriendSelectProps) => {
  const currentSiteValue = useRecoilValue(currentSite);
  const { data } = useSWR(
    `/admins?permissionGroup.site=${
      currentSiteValue!._id
    }&permissionGroup.tag=friend${includeOwner ? '|owner' : ''}${
      includeArcade ? '|arcade' : ''
    }`
  );
  return (
    <Select
      showSearch
      allowClear={showEmpty}
      optionFilterProp="children"
      filterOption={(input, option) => {
        const target = option?.children?.toString()?.toLowerCase();
        const userInput = input.toLowerCase();
        return !!target?.includes(userInput);
      }}
      placeholder="입주사 선택"
      loading={!data}
      style={{ width }}
      {...props}
    >
      {showEmpty && <Select.Option value="">{emptyText}</Select.Option>}
      {data &&
        data.admins.map((key: any) => (
          <Select.Option
            key={key._id}
            value={
              documentIdAsValue
                ? key._id
                : documentNameAsValue
                ? key.name
                : key.id
            }
          >
            {key.name}
          </Select.Option>
        ))}
    </Select>
  );
};

export default FriendSelect;
