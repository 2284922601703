import { useEffect, useState } from 'react';
import useLocalStorage from './useLocalStorage';

declare global {
  interface Window {
    setDevMode: (newVal: boolean) => void;
  }
}

const useDevMode = () => {
  const [devMode, setDevMode] = useLocalStorage('devMode', false);

  // 콘솔로 제어
  useEffect(() => {
    window.setDevMode = (newVal: boolean) => {
      setDevMode(newVal);
    };
  }, [setDevMode]);

  return [devMode, setDevMode] as const;
};

export default useDevMode;
