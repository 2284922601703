import { DateValue } from './index';

export interface MongoDocument<T = string> {
  _id: T;
}

export interface Deletable {
  deleted: boolean;
}

export interface Timestamp {
  createdAt: DateValue;
  updatedAt: DateValue;
}

/** @deprecated */
export interface PaginationMeta {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  prevPage?: number;
  nextPage?: number;
}

export interface Pagination {
  page: number;
  limit: number;

  totalDocs: number;
  totalPages: number;

  hasPrevPage: boolean;
  prevPage?: number;

  hasNextPage: boolean;
  nextPage?: number;
}

export const RotationType = {
  none: '없음',
  day: '요일제',
  two: '2부제',
  five: '5부제',
  ten: '10부제',
} as const;

export const RotationStatus = {
  bypass: '부제 예외',
  day: '요일제 위반',
  two: '2부제 위반',
  five: '5부제 위반',
  ten: '10부제 위반',
} as const;

export interface RotationOption {
  type: keyof typeof RotationType;
  startAt: DateValue;
  endAt: DateValue;
  days: [boolean, boolean, boolean, boolean, boolean, boolean, boolean];
}

export interface Site extends MongoDocument, Timestamp, Deletable {
  name: string;
  address1: string;
  address2?: string;
  location: { coordinates: number[] };
  closingHour: number;
  region?: string;
  siteType: string;
  rotation?: {
    behavior: boolean;
    text: { first: string; second: string };
    normalOption: RotationOption;
    ticketOption: RotationOption;
  };
}

export interface Cam extends MongoDocument {
  name: string;
  rtsp: string;
}

export const FacilityKind = {
  entrance_lpr: '입구 LPR',
  exit_lpr: '출구 LPR',
  internal_entrance_lpr: '내부 입구 LPR', // 요금 책정이 재개되는 입구
  internal_exit_lpr: '내부 출구 LPR', // 요금 책정을 멈추는 출구
  prepay_machine: '사전정산기',
  exit_auto_pay_machine: '출구정산기',
} as const;

export const FacilityIssueEventType = {
  '01': '온도',
  '02': '네트워크 끊김',
  '03': 'CPU',
  '04': '카드리더기',
  '05': '트리거',
} as const;

export const FacilityIssueStatus = {
  occur: '발생',
  confirm: '확인',
  recover: '복구',
  end: '종료',
} as const;

export interface FacilityIssue extends MongoDocument, Timestamp {
  site: Site;
  facility: Facility;
  status: keyof typeof FacilityIssueStatus;
  facilityIssueEventCode: keyof typeof FacilityIssueEventType;
  occurredAt: DateValue;
  confirmedAt?: DateValue;
  recoveredAt?: DateValue;
  closedAt?: DateValue;
  remarks: {
    confirmed?: string;
    recovered?: string;
    closed?: string;
  };
  exclusion?: boolean;
}

export interface VehicleCounter extends MongoDocument, Timestamp {
  site: Site['_id'];

  name: string;
  order: number;

  max: number;
  current: number;
}

export interface Facility extends MongoDocument, Timestamp {
  site: Site;
  name: string;
  kind: keyof typeof FacilityKind;
  order: number;

  configuration: {
    mode: string;
    waiting_msg: string;
    recog_msg: string;
    recog_sound_keyword: string;
    recog_gate_open: boolean;
    unrecog_msg: string;
    unrecog_sound_keyword: string;
    unrecog_gate_open: boolean;
  };

  cams: Cam[];
  images: any[];
  currentImage?: string;

  isLPR: boolean;

  hasIssue: boolean;
  recentIssues: FacilityIssue[];
  vehicleCounter?: VehicleCounter;

  lastHeartbeat: string;
  lastCPUPercent: number;
  lastTemperature: number;
  currentRecognition?: {
    image?: any;
    accessedAt: DateValue;
    plateNumber: string;
    vehicleAccess?: VehicleAccess;
  };
}

export interface Permissions {
  user: string;
  admin: string;
  site: string;
  permissionGroup: string;
  facility: string;
  vehicle: string;
  vehicleAccess: string;
  vehicleBan: string;
  patrolReport: string;
  patrolSchedule: string;
  patrolPoint: string;
  parkingIssue: string;
  parkingIssuePolicy: string;
  parkingCostPlan: string;
  discountCoupon: string;
  seasonTicket: string;
}

export interface Menu {
  name: string;
  tags: string[];
  value: string;
  children: Menu[];
  icon?: string;
}

export interface PermissionGroup extends MongoDocument, Timestamp, Deletable {
  name: string;
  tag: string;
  site?: Site;
  grantablePermissionGroups: any[];
  description: string;
  permissions: Permissions;
  menu: Menu[];

  isAdmin: boolean;
  isFriend: boolean;
  deletable: boolean;
}

type OwnerModel<T> = T &
  ({ owner: User; ownerModel: 'User' } | { owner: Admin; ownerModel: 'Admin' });

type PayerModel<T> = T &
  ({ payer: User; payerModel: 'User' } | { payer: Admin; payerModel: 'Admin' });

interface BaseFile {
  /** 원본파일명 */
  name: string;

  /** Content-Type */
  type: string;

  folder: string;

  temp: boolean;
}

export type File = OwnerModel<BaseFile>;

export const AccountApproveStatus = {
  pending: '대기',
  approved: '승인',
  rejected: '거부',
} as const;

export interface Account extends MongoDocument, Deletable, Timestamp {
  approvedStatus: keyof typeof AccountApproveStatus;
  passwordAttempts: number;
  passwordChangedAt?: DateValue;
}

export interface Admin extends Account {
  id: string;
  permissionGroup: PermissionGroup;

  email?: string;

  name?: string;
  name2?: string;

  phone?: string;
  phone2?: string;

  address1: string;
  address2?: string;
  postNumber?: string;

  allowedSites?: string[]; // Site[]
}

export interface User extends Account {
  email: string;
  friend?: Admin;

  info: {
    name: string;
    phone: string;
    department?: string;
    position?: string;
  };
}

export interface CashRecord extends MongoDocument, Timestamp {
  site: string;
  admin: Admin;
  facility: Facility;
  date: string;
  value: number;
  remarks?: string;
}

export const DiscountType = {
  '01': '종일할인',
  '02': '시간할인',
  '03': '정액할인',
  '04': '정률할인',
} as const;

export const DiscountTypeRev = {
  종일할인: '01',
  시간할인: '02',
  정액할인: '03',
  정률할인: '04',
} as const;

export interface DiscountCouponKey extends MongoDocument, Deletable {
  name: string;
  discountClssCode: keyof typeof DiscountType;
  value: number;
  price: number;

  defaultSupplyCount?: number;
  duplicateLimit?: number;
  skipDefaultCost?: boolean;

  count: number;
  nextCoupon: string;
  supply?: any; // TODO: DiscountCouponSupply
}

interface BaseDiscountCoupon extends MongoDocument, Timestamp {
  site: Site;
  discountCouponKey: DiscountCouponKey;

  uses?: {
    vehicle: string;
    vehicleAccess: VehicleAccess;
    applyAt: DateValue;
  };

  isQR: boolean;
  isRecovered: boolean;

  header?: string;
  footer?: string;
  color?: string;

  expiresAt?: DateValue;
  originalExpiresAt?: DateValue;
}

export type DiscountCoupon = OwnerModel<BaseDiscountCoupon>;

export interface Barcode extends MongoDocument, Timestamp {
  site: Site;
  barcode: string;
  balance: number;
  usedAt: DateValue;
}

export const DiscountCouponHistoryType = {
  mark: '사용',
  unmark: '사용취소',
};

export interface DiscountCouponHistory extends MongoDocument, Timestamp {
  site: Site;
  discountCoupon: DiscountCoupon;
  discountCouponHistoryClssCode: keyof typeof DiscountCouponHistoryType;
  usedAt: DateValue;
  applicant: MongoDocument['_id'];
  applicantObject: Admin | User | Facility;
  applicantName: string;
  vehicle: string;
  isCancelled: boolean;

  friend?: Admin;
  vehicleAccess?: VehicleAccess;
  // reservation?: VehicleAccessReservation;
  reservation?: VehicleAccessReservation['_id'];
}

export interface SeasonTicketGroup extends MongoDocument, Deletable, Timestamp {
  site: Site;
  owner: Admin;
  name: string;
  quantity: number;
  usedQuantity: number;
  paymentType: string;
  startAt?: DateValue;
  endAt?: DateValue;
  remarks?: string;

  // day: boolean[];
  // date?: number;
}

export const SeasonTicketGroupPaymentType = {
  유료: '유료',
  무료: '무료',
  후불결제: '후불결제',
  삭제: '삭제',
} as const;

export interface SeasonTicketGroupPayment extends MongoDocument, Timestamp {
  site: Site;
  seasonTicketGroup: SeasonTicketGroup;
  issuer: Admin;
  owner: Admin;
  price: number;
  quantity: number;
  paymentType: keyof typeof SeasonTicketGroupPaymentType;
}

export const SeasonTicketStatus = {
  '01': '신규',
  '02': '등록대기',
  '04': '사용중',
  '05': '사용종료',
  '06': '삭제대기',
  '07': '결제대기',
  '08': '후불결제대기',
} as const;

export const SeasonTicketStatusRev = {
  신규: '01',
  등록대기: '02',
  사용중: '04',
  사용종료: '05',
  삭제대기: '06',
  결제대기: '07',
  후불결제대기: '08',
} as const;

export interface SeasonTicket extends MongoDocument, Timestamp {
  vehicle: string;

  seasonTicketGroup: SeasonTicketGroup;
  status: keyof typeof SeasonTicketStatus;
  paymentType: string;

  startAt: DateValue;
  endAt: DateValue;

  friend?: Admin;
  user?: {
    name?: string;
    phone?: string;
    email?: string;
    address?: string;
    department?: string;
    position?: string;
    unit?: string;
  };

  remarks?: string;
  purpose?: string;
  vehicleType?: string;
  vehicleModelName?: string;

  waitingSequence: number;
  allowedCounters?: string[]; // VehicleCounter[]

  // 정기권에 지정되는 주차요금 시간표 ObjectId
  parkingCostPlanTable?: ParkingCostPlanTable;
}

export const PaymentType = {
  '01': '사전정산',
  '02': '출구정산',
  '03': '후불정산',
  '04': '무료출차', // WHY?
};

interface BasePayment extends MongoDocument, Timestamp {
  site: Site;
  friend?: Admin;
  vehicle: string;
  va: VehicleAccess;
  price: {
    cost: number;
    discount: number;
    total: number;
  };
  paidType: keyof typeof PaymentType;

  paymentMethod: string;
  cardInfo: {
    transitionCode?: string;
    approvedNumber?: string;
    approvedAt?: DateValue;
    description?: string;
    number?: string;
  };
  paidAt: DateValue;
  TID?: string;
}

export type Payment = PayerModel<BasePayment>;

export const ReservationStatus = {
  '01': '사용중',
  '02': '사용종료',
  '03': '삭제대기',
};

interface BaseVehicleAccessReservation extends MongoDocument {
  site: Site;

  startAt: DateValue;
  endAt: DateValue;

  vehicle: string;
  discountCouponKey?: string;
  status: keyof typeof ReservationStatus;

  user: {
    name?: string;
    phone?: string;
    address?: string;
    company?: string;
    department?: string;
    position?: string;
    vehicleModel?: string;
  };

  visitLimit: number;
  visitCount?: number;
  bypassRotation?: boolean;

  vehicleType?: string;
  remarks?: string;
  purpose?: string;
}

export type VehicleAccessReservation = OwnerModel<BaseVehicleAccessReservation>;

export interface Image {
  file: string;
  angle: string;
}

export interface AccessLog {
  accessedAt: DateValue;
  facility?: Facility;
  image?: Image;
  extraImages?: Image[];
  confirmed?: boolean;
}

export const VehicleAccessStatus = {
  inParking: '주차중',
  inExit: '출차대기',
  prePaid: '사전정산완료',
  postpayReserved: '출차정산대기',
  postPaid: '출차정산완료',
  exitPaid: '출구정산완료',
  closed: '마감완료',
  nonePaid: '무료출차',
  noEntry: '입차기록없음',
  noExit: '출차기록없음',
  manualExit: '수동출차',
} as const;

export const VehicleAccessStatusRev = {
  주차중: 'inParking',
  출차대기: 'inExit',
  사전정산완료: 'prePaid',
  출차정산대기: 'postpayReserved',
  출차정산완료: 'postPaid',
  출구정산완료: 'exitPaid',
  마감완료: 'closed',
  무료출차: 'nonePaid',
  입차기록없음: 'noEntry',
  출차기록없음: 'noExit',
  수동출차: 'manualExit',
} as const;

export interface VehicleAccess extends MongoDocument, Timestamp, Deletable {
  status: keyof typeof VehicleAccessStatus;

  vehicle: string;
  entry?: AccessLog;
  exit?: AccessLog;
  lastPrepayAccessedAt?: DateValue;
  accessedAt: DateValue;
  lastAccessedAt: DateValue;

  friends?: string[]; // Admin[];

  info: {
    name?: string;
    email?: string;
    phone?: string;
    company?: string;
    department?: string;
    position?: string;
  };

  reservations: VehicleAccessReservation[];
  rotation?: keyof typeof RotationStatus;

  payment: {
    logs: any[];
    oldLogs: any[];
    seasonTicket?: SeasonTicket;
    discountCouponList: DiscountCoupon[];
    barcodes: Barcode[];
    cash: number;
    cost: number;
    discountCost: number;
    paidCost: number;
    costSum: number;
    totalCost: number;
    costWithoutCoupon: number;
    costExceeded: boolean;
    discountExceeded: boolean;
  };

  prepayment_id?: Payment;
  payment_id?: Payment;
  postpayment_id?: Payment;

  remarks?: string;
  internalAccesses?: {
    type: 'internal_entrance' | 'internal_exit';
    facility: Facility;
    accessedAt: DateValue;
    image?: Image;
    extraImages?: Image[];
    confirmed?: boolean;
  }[];

  accessDuration?: number;
}

export const VoCSourceType = {
  main_phone: '대표전화',
  voip_phone: 'VoIP폰',
  app_report: 'App불편신고',
  other: '기타',
} as const;

export const VoCCustomerType = {
  '': '입주민',
  visitor: '방문고객',
  friend_manager: '입주사총무',
  owner: '건물주',
  store: '상가',
  admin: '관리직원',
  other: '기타',
} as const;

export const VoCReportType = {
  vehicle_number: '차번인식',
  entry: '입차',
  exit: '출차',
  facility: '주차설비',
  discount_coupon: '할인권적용',
  question: '문의',
  complain: '불만',
  compliment: '칭찬',
  proposal: '건의',
  suggestion: '제안',
  non_voc: 'VoC이외',
  payment: '결제문의',
  reservation: '방문등록',
  accident: '사고처리',
  register: '회원가입',
  ticket_purchase: '주차권구매',
  season_ticket_request: '정기권신청',
  other: '기타',
} as const;

export const VoCFacilityTypeLv1 = {
  lv1_hw: 'HW',
  lv1_sw: 'SW',
  lv1_app: 'APP',
  lv1_web: 'WEB',
} as const;

export const VoCFacilityTypeLv2 = {
  lv2_lpr: 'LPR',
  lv2_auto_pay_machine: '무인정산기',
  lv2_loop_coil: '루프코일',
  lv2_beacon: '경광등',
  lv2_awining: '어닝',
} as const;

export const VoCReportFeedbackRequestType = {
  none: '없음',
  phone: '전화',
  sms: 'SMS',
  email: '이메일',
} as const;

export const VoCReportRatingType = {
  // 'normal': '일반',
  compliment: '칭찬',
  bad_service_attitude: '서비스태도불량',
  customer_business_interference: '고객업무방해',
  neglect_broken_facility: '고장시설물방치',
  poor_service_quality: '서비스품질불량',
} as const;

export const VoCDepartmentType = {
  remote_sbi: '원격_SBI',
  as_sbi: 'AS_SBI',
  operation_center: '운영센터',
  emergency: '긴급출동',
  remote_ioc: '원격_IOC',
  sni_parking_team: 'SNI_주차팀',
  other: '기타',
} as const;

export const VoCProgressType = {
  done: '처리완료',
  request_cancel: '고객요청취소',
  manager_cancel: '접수자처리취소',
} as const;

export interface VoC extends MongoDocument<number> {
  site: Site;

  /** 접수일시 */
  date: DateValue;

  /** 접수채널 */
  source?: keyof typeof VoCSourceType;

  /** 접수구분 */
  label?: string;

  /** [고객정보] */
  customer: {
    /** 신고자 */
    name?: string;

    /** 요청회사 */
    friend?: Admin;

    /** 이메일 */
    email?: string;

    /** 접수전화번호 */
    phone?: string;

    /** 성별 */
    sex?: string;

    /** 고객유형 */
    type?: keyof typeof VoCCustomerType;

    /** 차량번호 */
    plateNumber?: string;
  };

  /** [접수정보] */
  report: {
    /** 접수제목 */
    title?: string;

    /** 접수상세내역 */
    content?: string;

    /** 현장명 */
    site?: Site;

    /** VoC 유형 */
    type?: keyof typeof VoCReportType;

    /** 설비유형 lv1 */
    facilityType1?: keyof typeof VoCFacilityTypeLv1;
    /** 설비유형 lv2 */
    facilityType2?: keyof typeof VoCFacilityTypeLv2;

    /** 피드백요청 */
    feedbackRequest?: keyof typeof VoCReportFeedbackRequestType;

    /** 기타상세 */
    details?: string;

    /** 예약처리 */
    reservation?: Date;

    /** 칭찬/불만 */
    rating?: keyof typeof VoCReportRatingType;

    files?: [string]; // [File];
  };

  /** [배분처리] */
  allocation: {
    /** 처리부서/파트 */
    department?: keyof typeof VoCDepartmentType;

    /** 전달특이사항 */
    note?: string;
  };

  /** [중간진행상황] */
  progress: {
    /** 처리진행상황관리 */
    status?: string;

    /** 반복접수횟수 */
    duplicateReportCount?: number;

    /** 반복접수이력 */
    duplicateReportLogs?: [string];
  };

  /** [결과처리] */
  result: {
    /** 처리자 */
    manager?: string;

    /** 처리완료예정일 */
    dueAt?: DateValue;

    /** 처리완료일시 */
    completeAt?: DateValue;

    /** 처리부서/파트 */
    department?: keyof typeof VoCDepartmentType;

    /** 진행상태 */
    progressStatus?: keyof typeof VoCProgressType;

    /** 처리내용 (내부) */
    detail?: string;

    /** 고객제공용 처리결과 */
    defailForCustomer?: string;

    /** 결과처리파일 */
    files?: [string]; // [File];
  };
}

export interface ParkingCostPlanTable extends MongoDocument {
  site: Site;
  name: string;
}

export interface QRCodeCoupon {
  owner: Admin;
  discountCouponKey: DiscountCouponKey;
  expiresAt: DateValue | null;

  nextCoupon: string;
  usedCount: number;
  totalCount: number;
}

export const TowerAccessStatus = {
  입고: '입고',
  출차대기: '출차대기',
  출차중: '출차중',
  출차완료: '출차완료',
};

export interface TowerAccess extends MongoDocument {
  site: string;
  parkingTowerId: string;
  plateNumber: string;
  parkingCode: string;
  status: keyof typeof TowerAccessStatus;
  requestedAt?: DateValue | null;
  releasedAt?: DateValue | null;
}
