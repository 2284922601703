import { Button, Descriptions, DescriptionsProps, Typography } from 'antd';
import { VehicleAccess, VehicleAccessStatus } from '@utils/models';
import { formatDistanceTo } from '@utils';
import RemarkModal from '@pages/Parking/modals/RemarkModal';
import { useState } from 'react';
import AccessLogText from './AccessLogText';
import { format, formatDuration, add, intervalToDuration } from 'date-fns';
import Timestamp from '@pages/CloudWatch/components/Timestamp';

import { Image } from 'antd';
import { IMAGE_CDN, IMAGE_FALLBACK } from '@utils';

import { ko } from 'date-fns/locale';

interface VehicleDescriptionsProps extends DescriptionsProps {
  va?: VehicleAccess;
  revalidate?: () => void;
}

export default ({ va, revalidate, ...props }: VehicleDescriptionsProps) => {
  const [remarkVisible, setRemarkVisible] = useState(false);

  const duration = va?.accessDuration
    ? intervalToDuration({
        start: new Date(0),
        end: add(new Date(0), { minutes: va.accessDuration }),
      })
    : undefined;

  return (
    <Descriptions bordered column={1} {...props}>
      <Descriptions.Item label="차량 ID" contentStyle={{ fontSize: '14px' }}>
        <code>{va?._id}</code>
      </Descriptions.Item>
      <Descriptions.Item label="권종">
        {va?.payment?.seasonTicket ? (
          <>
            정기권
            {va.payment.seasonTicket.user?.name ||
            va.payment.seasonTicket.user?.department ? (
              <Typography.Text type="secondary">
                &nbsp;(
                {[
                  va.payment.seasonTicket.user.name ?? '(이름 없음)',
                  va.payment.seasonTicket.user.department,
                ]
                  .filter((x) => x)
                  .join(', ')}
                )
              </Typography.Text>
            ) : (
              ''
            )}
          </>
        ) : (
          '일반'
        )}
      </Descriptions.Item>
      <Descriptions.Item label="상태">
        {va && VehicleAccessStatus[va.status]}
      </Descriptions.Item>
      <Descriptions.Item
        label="비고"
        contentStyle={{ paddingTop: 0, paddingBottom: 0, paddingRight: '16px' }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {va?.remarks || '-'}

          <RemarkModal
            vehicleAccess={va}
            visible={remarkVisible}
            onCancel={() => setRemarkVisible(false)}
            onOk={() => [setRemarkVisible(false), revalidate?.()]}
          />
          <Button onClick={() => setRemarkVisible(true)}>수정</Button>
        </div>
      </Descriptions.Item>
      <Descriptions.Item label="입차일시">
        <AccessLogText log={va?.entry} />
      </Descriptions.Item>
      <Descriptions.Item label="사전정산">
        {va?.lastPrepayAccessedAt
          ? format(new Date(va.lastPrepayAccessedAt), 'yyyy-MM-dd HH:mm:ss')
          : '-'}
      </Descriptions.Item>
      <Descriptions.Item label="출차일시">
        <AccessLogText log={va?.exit} />
      </Descriptions.Item>
      <Descriptions.Item label="주차시간">
        {duration
          ? formatDuration(duration, {
              locale: ko,
            })
          : '-'}
      </Descriptions.Item>
      {va?.internalAccesses?.length ? (
        <Descriptions.Item label="내부 입출차">
          {va?.internalAccesses.map((ia, i) => (
            <p key={i}>
              <Image
                height={48}
                fallback={IMAGE_FALLBACK}
                src={
                  ia.image ? `${IMAGE_CDN}/${ia.image.file}` : IMAGE_FALLBACK
                }
                // preview={
                //   !!image && {
                //     mask: (
                //       <span>
                //         <EyeOutlined /> 자세히 보기
                //         {images.length > 1 &&
                //           ` (${index + 1}/${images.length})`}
                //       </span>
                //     ),
                //   }
                // }
              />
              <AccessLogText log={ia} /> (
              {
                {
                  internal_exit: '내부 출차',
                  internal_entrance: '내부 입차',
                }[ia.type]
              }
              )
            </p>
          ))}
        </Descriptions.Item>
      ) : null}
    </Descriptions>
  );
};
